<template>
	<v-dialog
		v-model="show_bid_box"
		height="auto"
		width="1280"
		transition="slide-fade"
		hide-overlay
		light
	>
		<div>
			<v-card min-height="100%" min-width="100%" class="my-card d-flex">
				<v-row no-gutters dense>
					<v-row no-gutters dense class="d-flex">
						<v-col cols="12">
							<p class="heading-sf20 pb-4">Bid</p>
						</v-col>

						<v-col cols="12" class="list-header pb-4">
							<v-row no-gutters dense>
								<v-col cols="6" class="d-flex align-center">
									<img
										v-if="bid_info.category_id === 1"
										:src="$store.state.icons.icons['Lumber']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="bid_info.category_id === 2"
										:src="$store.state.icons.icons['Electric']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="bid_info.category_id === 3"
										:src="$store.state.icons.icons['Plumbing']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="bid_info.category_id === 4"
										:src="$store.state.icons.icons['Masonry']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="bid_info.category_id === 5"
										:src="$store.state.icons.icons['ToolBoxes']"
										style="height:36px;"
										alt
									/>

									<div class="pl-7">
										<p class="heading-mf15">{{ bid_info.list_name }}</p>
										<p class="heading-rf13">Bid ref: {{ bid_info.list_id }}</p>
									</div>
								</v-col>
								<v-col cols="6" class="d-flex align-center justify-end">
									<app-bid-hours v-model="bid_info.created_at" v-if="bid_info.status === 'AC'"></app-bid-hours>
									<div
										v-if="bid_info.status === 'AC'"
										class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
									>Active</div>
									<div
										v-else-if="bid_info.status === 'A'"
										class="text-tag-green text-tag-btn d-flex align-center justify-center my-2"
									>Accepted</div>
									<div
										v-else-if="bid_info.status === 'E'"
										class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
									>Expired</div>
									<div
										v-else-if="bid_info.status === 'R'"
										class="text-tag-red text-tag-btn d-flex align-center justify-center my-2"
									>Rejected</div>
									<div
										v-else-if="bid_info.status === 'LB'"
										class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
									>Lost Bid</div>
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12" class="pt-6">
							<div>
								<div class="heading-sf16 pb-2">Bid Items</div>
								<div class="pb-4">
									<v-simple-table>
										<template v-slot:default>
											<thead>
												<tr>
													<th class="text-left">Name</th>
													<th class="text-left">Qty</th>
													<th class="text-left">Unit Price</th>
													<th class="text-left">Total</th>
													<th class="text-left">Note</th>
													<th class="text-left">Modification</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in bid_info.bid_items" :key="index">
													<td>{{ item.name }}</td>
													<td>{{ item.qty }}</td>
													<td>{{ $options.filters.currency(item.price) }}</td>
													<td>{{ $options.filters.currency(item.qty * item.price) }}</td>
													<td>{{ item.notes !== null ? item.notes : '-' }}</td>
													<td>{{ item.modification === 'Y' ? 'Yes' : 'No' }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</div>
							</div>
						</v-col>

						<v-col cols="12" class="pt-6">
							<v-row no-gutters dense>
								<v-col cols="12" class="heading-sf16 pb-2">Bid Information</v-col>
								<v-col cols="6" class="pr-4 pb-4 list-item-view">
									<div class="d-flex pb-2">
										<div class="list-detail-title">Company Name:</div>
										<div class="heading-rf15">{{ bid_info.company_name }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Delivery Address:</div>
										<div class="heading-rf15">{{ bid_info.address }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Required delivery date:</div>
										<div class="heading-rf15">{{ bid_info.delivery_date }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Declared delivery date:</div>
										<div class="heading-rf15">{{ bid_info.declare_delivery_date }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Required delivery time:</div>
										<div class="heading-rf15">{{ requiredDeliveryTime(bid_info.delivery_time) }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Declared delivery time:</div>
										<div class="heading-rf15">{{bid_info.start_time + " - " + bid_info.end_time }}</div>
									</div>
								</v-col>
								<v-col cols="6" class="pl-4 pt-2 pb-4">
									<div class="d-flex pb-2">
										<div class="list-detail-title">Sub Total:</div>
										<div class="heading-rf15">{{  $options.filters.currency(bid_info.sub_total) }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Shipping Charge:</div>
										<div class="heading-rf15">{{  $options.filters.currency(bid_info.shipping) }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Service Fee (5%):</div>
										<div class="heading-rf15">{{  $options.filters.currency(bid_info.service_fee) }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Sales Tax ({{ bid_info.tax_percentage }}%):</div>
										<div class="heading-rf15">{{  $options.filters.currency(bid_info.sales_tax) }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Total Amount:</div>
										<div class="heading-rf15">{{  $options.filters.currency(bid_info.total_amount) }}</div>
									</div>
								</v-col>
							</v-row>
						</v-col>
					</v-row>

					<v-row no-gutters dense align="stretch" class="d-flex">
						<v-col cols="12" class="d-flex justify-end mt-auto pt-4">
							<v-btn class="my-outline-btn" id="my_elevation" @click="show_bid_box = false" depressed>Close</v-btn>
						</v-col>
					</v-row>
				</v-row>
			</v-card>
		</div>
	</v-dialog>
</template>

<script>
import { bidDetails } from '../../services/bidservice';
import { dateFormat, timeFormat, covLocalTime, covLocalDate } from '../../services/commonservice';
export default {
	data() {
		return {
			show_bid_box: false,
			bid_info: {
				bid_items: []
			}
		};
	},

	methods: {
		async openBidView(id) {
			let data = await bidDetails(id);

			this.bid_info.bid_id = data.data.bid_id;
			this.bid_info.list_id = data.data.list_id;
			this.bid_info.category_id = data.data.clist.category_id;
			this.bid_info.list_name = data.data.clist.list_name;
			this.bid_info.address = data.data.clist.address;
			this.bid_info.status = data.data.status;
			this.bid_info.delivery_date = dateFormat(data.data.clist.delivery_date);
			this.bid_info.declare_delivery_date = covLocalDate(data.data.date_of_delivery,  data.data.start_time);
			this.bid_info.created_at = data.data.created_at;
			this.bid_info.delivery_time = data.data.clist.delivery_time;
			this.bid_info.declare_delivery_time = data.data.time_of_delivery;
			this.bid_info.start_time = covLocalTime(data.data.date_of_delivery, data.data.start_time);
			this.bid_info.end_time = covLocalTime(data.data.date_of_delivery, data.data.end_time);
			this.bid_info.notes = data.data.clist.notes;
			this.bid_info.company_name = data.data.distributor_company.name;
			this.bid_info.sub_total = data.data.sub_total;
			this.bid_info.shipping = data.data.shipping;
			this.bid_info.service_fee = data.data.service_fee;
			this.bid_info.sales_tax = data.data.sales_tax;
			this.bid_info.tax_percentage = data.data.tax_percentage;
			this.bid_info.total_amount = data.data.total_amount;

			this.bid_info.bid_items = [];

			data.data.biditems.forEach(item => {
				let ob = {
					name: item.name,
					qty: item.qty,
					price: item.price,
					notes: item.notes,
					modification: item.modification
				}

				this.bid_info.bid_items.push(ob);
			});
			this.show_bid_box = true;
		},
		requiredDeliveryTime(time) {
			if (time === "M") {
				return "Morning";
			} else if (time === "N") {
				return "Noon";
			} else if (time === "A") {
				return "Afternoon";
			} else if (time === "L") {
				return "Last Stop";
			} else if (time === "AS") {
				return "As soon as possible";
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.list-header {
	border-bottom: 1px solid #dde2ee;
}

.list-item-view {
	border-right: 1px solid #dde2ee;
}

.list-detail-title {
	font-family: Inter-Regular;
	font-size: 15px;
	color: #7687a2;
	width: 200px;
}
@media (min-width: 1080px) {
	.theme--light.v-data-table table {
		min-width: 100% !important;
	}
}
</style>